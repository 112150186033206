import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import styled from "styled-components";
import PropTypes from "prop-types";

gsap.registerPlugin(ScrollTrigger);

const BaseFade = styled.div``;

const Fade = ({ children, delay, markers }) => {
  const ref = useRef();

  if (!delay) {
    delay = 0;
  }

  useEffect(() => {
    gsap.set(ref.current, {
      y: 50,
      opacity: 0,
      duration: 1,
      delay: delay,
      markers: markers,
      ease: "power3.out",
      overwrite: "auto"
    });

    const animation = ScrollTrigger.create({
      trigger: ref.current,
      start: "top 90%",
      end: "bottom 35%",
      markers: false,
      once: true,
      onEnter: () =>
        gsap.to(ref.current, {
          y: 0,
          opacity: 1,
          stagger: 0.2
        })
    });

    return () => animation.kill();
  }, [delay, markers]);

  return <BaseFade ref={ref}>{children}</BaseFade>;
};

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  markers: PropTypes.bool
};

export default Fade;
