import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Carousel } from "@primitives/carousel";
import { RotatableSvg } from "@primitives/rotatableSvg";

const Arrow = ({ className, direction = "right" }) => (
  <RotatableSvg
    className={className}
    direction={direction}
    fill="currentColor"
    height="27"
    viewBox="0 0 24 27"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58984 18.5971L13.1698 13.7059L8.58984 8.81477L9.99984 7.31226L15.9998 13.7059L9.99984 20.0996L8.58984 18.5971Z"
      fill="currentColor"
    />
  </RotatableSvg>
);

Arrow.propTypes = {
  className: PropTypes.string,
  direction: RotatableSvg.propTypes.direction
};

const ButtonContainer = styled.div(({ theme }) => ({
  alignItems: "center",
  borderTopColor: theme.colors.gray5,
  borderTopStyle: "solid",
  borderTopWidth: 1,
  display: "flex",
  justifyContent: "space-between",
  marginTop: 24,
  paddingBottom: 15,
  paddingTop: 15
}));

const ButtonWrapper = styled.div({
  lineHeight: 0
});

const Button = styled.button(({ theme }) => ({
  cursor: "pointer",
  "&:disabled": {
    color: theme.colors.gray6,
    cursor: "default"
  }
}));

export const BottomButtonCarousel = ({
  children,
  itemWidth,
  spaceBetweenItems
}) => {
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const onNext = () => carouselRef.current.next();
  const onPrevious = () => carouselRef.current.previous();

  const onItemChange = ({ atStart, atEnd, currentIndex }) => {
    setAtStart(atStart);
    setAtEnd(atEnd);
    setCurrentIndex(currentIndex);
  };

  return (
    <div>
      <Carousel
        itemWidth={itemWidth}
        onChange={onItemChange}
        ref={carouselRef}
        spaceBetweenItems={spaceBetweenItems}
      >
        {children}
      </Carousel>

      <ButtonContainer>
        <ButtonWrapper>
          <Button className="mr-sm" disabled={atStart} onClick={onPrevious}>
            <Arrow direction="left" />
          </Button>
          <Button disabled={atEnd} onClick={onNext}>
            <Arrow direction="right" />
          </Button>
        </ButtonWrapper>
        <p className="font--caption">
          {currentIndex + 1} of {React.Children.count(children)}
        </p>
      </ButtonContainer>
    </div>
  );
};

BottomButtonCarousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  itemWidth: PropTypes.string,
  spaceBetweenItems: PropTypes.number
};
