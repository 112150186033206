import styled from "styled-components";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { theme } from "@src/theme";
import "swiper/swiper.min.css";

const Wrapper = styled.div({
  ".swiper-slide": {
    height: "auto",
    width: ({ itemWidth }) => itemWidth
  }
});

export const CarouselHeading = styled.div`
  min-height: 6em;
  max-width: 20em;

  @media ${theme.breakpoints.notSmall} {
    max-width: 30em;
  }

  @media ${theme.breakpoints.large} {
    max-width: 40em;
  }
`;

export const Carousel = forwardRef(
  (
    {
      className,
      children,
      itemsPerView = "auto",
      itemWidth = "100%",
      onChange,
      spaceBetweenItems = 0,
      loop = false
    },
    ref
  ) => {
    const [swiper, setSwiper] = useState(null);

    useImperativeHandle(ref, () => ({
      next: () => swiper.slideNext(),
      previous: () => swiper.slidePrev()
    }));

    const onTransition = (instance) => {
      if (onChange) {
        onChange({
          atStart: instance.isBeginning,
          atEnd: instance.isEnd,
          currentIndex: instance.activeIndex
        });
      }
    };

    const onSwiper = (instance) => {
      setSwiper(instance);
    };

    useEffect(() => {
      if (swiper && !swiper.destroyed) {
        swiper.update();
      }
    }, [itemWidth, swiper]);

    return (
      <Wrapper className={className} itemWidth={itemWidth}>
        <Swiper
          onSwiper={onSwiper}
          onTransitionEnd={onTransition}
          slidesPerView={itemsPerView}
          spaceBetween={spaceBetweenItems}
          loop={loop}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    );
  }
);

Carousel.displayName = "Carousel";

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  itemsPerView: PropTypes.oneOf([PropTypes.number, "auto"]),
  itemWidth: PropTypes.string,
  onChange: PropTypes.func,
  spaceBetweenItems: PropTypes.number,
  loop: PropTypes.bool
};
