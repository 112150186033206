import styled from "styled-components";
import classnames from "classnames";
import PropTypes from "prop-types";

const Wrapper = styled.section.attrs(({ centered, screen }) => ({
  className: classnames({
    centered,
    screen
  })
}))`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &.centered {
    align-items: center;
  }

  &.screen {
    min-height: 100vh;
  }

  iframe {
    max-width: 100%;
  }
`;

const Section = ({ innerRef, children, ...props }) => {
  return (
    <Wrapper ref={innerRef} {...props}>
      {children}
    </Wrapper>
  );
};

Section.propTypes = {
  innerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.any })
  ]),
  children: PropTypes.node
};

export default Section;
