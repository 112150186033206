import styled from "styled-components";
import classnames from "classnames";
import Container from "@primitives/container";
import { theme } from "@src/theme";

export const BoxWrapper = styled(Container)`
  background-color: ${theme.colors.white};
  padding: 4rem 0;
  margin: 0;

  @media ${theme.breakpoints.large} {
    padding: 6rem 0;
  }
`;

export const OverlapSection = styled.div`
  position: relative;
  transform: translateY(50%);
  z-index: 1;
`;

export const OverlappedSectionCallout = styled.div`
  > * {
    padding-top: 16rem;
    margin-bottom: -2rem;
    max-height: auto;
    height: auto;

    @media ${theme.breakpoints.large} {
      padding-top: 45vh;
      margin-bottom: -4rem;
    }
  }
`;

export const GridLines = styled.div`
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  z-index: -1;
  width: 100%;

  span {
    background: ${theme.colors.gray3};
    height: 100%;
    position: absolute;
    width: 1px;

    &:nth-child(1) {
      @media ${theme.breakpoints.large} {
        left: 25%;
      }
    }

    &:nth-child(2) {
      left: 50%;
    }

    &:nth-child(3) {
      @media ${theme.breakpoints.large} {
        left: 75%;
      }
    }
  }

  &::before {
    content: "";
    background: ${theme.colors.gray3};
    left: 0;
    height: calc(100% + 20rem);
    position: absolute;
    width: 1px;
    top: 4rem;

    @media ${theme.breakpoints.large} {
      left: calc(${theme.spacing.xs} * -1);
    }
  }

  &::after {
    content: "";
    background: ${theme.colors.gray3};
    right: 0;
    height: calc(100% + 20rem);
    position: absolute;
    z-index: 0;
    width: 1px;

    @media ${theme.breakpoints.large} {
      right: calc(${theme.spacing.xs} * -1);
    }
  }
`;

export const Spacer = styled.div.attrs(({ xs, sm, md, lg, xl }) => ({
  className: classnames({
    xs,
    sm,
    md,
    lg,
    xl
  })
}))`
  display: flex;
  min-height: 2rem;

  @media ${theme.breakpoints.large} {
    min-height: 4rem;
  }

  &.xs {
    min-height: 1rem;

    @media ${theme.breakpoints.large} {
      min-height: 2rem;
    }
  }

  &.sm {
    min-height: 3rem;

    @media ${theme.breakpoints.large} {
      min-height: 6rem;
    }
  }

  &.md {
    min-height: 4rem;

    @media ${theme.breakpoints.large} {
      min-height: 8rem;
    }
  }

  &.lg {
    min-height: 5rem;

    @media ${theme.breakpoints.large} {
      min-height: 10rem;
    }
  }

  &.xl {
    min-height: 6rem;

    @media ${theme.breakpoints.large} {
      min-height: 12rem;
    }
  }
`;

export const NegativeSpacer = styled.div.attrs(({ sm, md, lg, xl }) => ({
  className: classnames({
    sm,
    md,
    lg,
    xl
  })
}))`
  display: block;
  margin-top: -2rem;

  @media ${theme.breakpoints.large} {
    margin-top: -4rem;
  }

  &.sm {
    margin-top: -3rem;

    @media ${theme.breakpoints.large} {
      margin-top: -6rem;
    }
  }

  &.md {
    margin-top: -4rem;

    @media ${theme.breakpoints.large} {
      margin-top: -8rem;
    }
  }

  &.lg {
    margin-top: -5rem;

    @media ${theme.breakpoints.large} {
      margin-top: -10rem;
    }
  }

  &.xl {
    margin-top: -6rem;

    @media ${theme.breakpoints.large} {
      margin-top: -12rem;
    }
  }
`;

export const Divider = styled.div`
  background-color: ${theme.colors.cream};
  height: 1px;
  width: 100%;
`;
