import styled, { css } from "styled-components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { theme } from "@src/theme";

const twoColumnStyles = css`
  @media ${theme.breakpoints.notSmall} {
    & > * {
      grid-column: auto / span 6;
    }
  }
`;

const threeColumnStyles = css`
  @media ${theme.breakpoints.notSmall} {
    & > * {
      grid-column: auto / span 6;
    }
  }

  @media ${theme.breakpoints.large} {
    & > * {
      grid-column: auto / span 4;
    }
  }
`;

const fourColumnStyles = css`
  & > * {
    grid-column: auto / span 6;
  }

  @media ${theme.breakpoints.notSmall} {
    & > * {
      grid-column: auto / span 6;
    }
  }

  @media ${theme.breakpoints.large} {
    & > * {
      grid-column: auto / span 3;
    }
  }
`;

const Wrapper = styled.div.attrs(({ rowGapNone, columnGapNone }) => ({
  className: classnames({
    rowGapNone,
    columnGapNone
  })
}))`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: ${theme.gap.md};
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  & > * {
    width: 100%;
    grid-column: auto / span 12;
  }

  ${({ columns }) => columns === 2 && twoColumnStyles}

  ${({ columns }) => columns === 3 && threeColumnStyles}

  ${({ columns }) => columns === 4 && fourColumnStyles}

  &.rowGapNone {
    grid-row-gap: 0;
  }

  &.columnGapNone {
    grid-column-gap: 0;
  }
`;

const Grid = ({ innerRef, children, ...props }) => {
  return (
    <Wrapper ref={innerRef} {...props}>
      {children}
    </Wrapper>
  );
};

Grid.propTypes = {
  innerRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.any })
  ]),
  children: PropTypes.node
};

export default Grid;
